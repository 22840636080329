import baseComponent from "@/scripts/baseComponent";
import fixedRentalApi from '@/api/gpuHub/fixedRental';
import lazyLoadComponent from '@/scripts/lazyLoadComponent';
import SkeletonBox from '@/components/SkeletonBox';
import { mapState } from "vuex";
import moment from 'moment';
import EventBus from "@/event/EventBus";

export default {

    extends: baseComponent,

    components: {
        FixedRentalSettings: lazyLoadComponent({
            componentFactory: () => import("@/views/components/FixedRental/FixedRentalSettings"),
            loading: SkeletonBox,
        }),
    },

    props: {
        elements: {
            type: Array,
            default: []
        },
    },

    data() {
        return {
            isEditAll: false,
            listData: [],
            confirmText: '',
        };
    },

    computed: {
        ...mapState({
            listServicePack: (state) => state.common.listServicePack,
        }),
    },

    created() {

    },

    mounted() {
        this.listData = JSON.parse(JSON.stringify(this.elements));
        this.listData.forEach(element => {
            element.startTime = moment.utc(element.startTime).local();
            element.endTime = moment.utc(element.endTime).local();
        });
    },

    methods: {

        // Fixed rental edit
        fixedRentalEdit() {
            let request = {
                listData: this.listData,
                deviceInformation: JSON.stringify(this.getInformationClient()),
            }
            this.feedStatusLoading(0);
            fixedRentalApi.fixedRentalEdit(request)
                .then((res) => {
                    try {
                        this.feedStatusLoading(1);
                        setTimeout(() => {
                            this.closePopup('fixed-rental-edit');
                            this.notificationSuccess(
                                "Success !",
                                "The changes have been applied successfully"
                            );
                            EventBus.$emit("fixed-rental-perform-action", { action: 'refreshList' });
                        }, 500);
                    } catch (error) {
                        console.log("getData -> error", error)
                        this.feedStatusLoading(3);
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                })
                .catch(error => {
                    try {
                        if (error.name === 'Error') {
                            // if (this.$route.path !== '/login') {
                            //     setTimeout(() => {
                            //         location.href = "/login";
                            //     }, 2000);
                            // }
                        } else {
                            switch (error.errorCode) {
                                default:
                                    console.log("getData -> error", error)
                                    // this.$store.dispatch("common/showUnkownError", error, "Error");
                                    break;
                            }
                        }
                    } catch (error) {
                        console.log("getData -> error", error)
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                    this.feedStatusLoading(3);
                });
        }
    }
} 